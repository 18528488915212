import { render, staticRenderFns } from "./TheProfile.vue?vue&type=template&id=35821705&scoped=true"
import script from "./TheProfile.vue?vue&type=script&lang=js"
export * from "./TheProfile.vue?vue&type=script&lang=js"
import style0 from "./TheProfile.vue?vue&type=style&index=0&id=35821705&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35821705",
  null
  
)

export default component.exports